<template>
    <div class="UserAgreement xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-text">
                <h3>服务协议</h3>
                <p>FU WU XIE YI</p>
            </div>
        </div>
        <div class="agreement-box">
            <div class="agreement-tit">
                <h3>服务协议</h3>
            </div>
            <div class="agreement-content">
                <h4>一、名词</h4>
                <p>1.平台：由河南千越医疗科技有限公司开发的所有软件、系统等产品（以下简称“本平台”）；</p>
                <p>2.服务：由河南千越医疗科技有限公司为各角色用户提供的所有服务；</p>
                <p>3.千越：河南千越医疗科技有限公司的简称（以下简称“千越”或“本公司”）。</p>
                <h4>二、总则</h4>
                <p>1.本公司根据本服务协议为个角色用户提供服务。本协议内容，可能会随着公司发展、平台发展、时长环境等因素随时更新调整，请您知晓。本协议一旦发生变动，即代替原来的服务协议并正式生效使用。</p>
                <p>2.您在享受本公司的所有服务之前，请先仔细阅读本服务协议。如您不同意本服务协议的任何条款，请您立即停止使用本公司提供的全部服务；如您开始或继续使用本平台，视为您已了解并完全同意本服务协议各项内容。</p>
                <h4>三、服务说明</h4>
                <p>1.本公司提供的所有服务，除非本服务协议另有其它明示规定，均受到本服务协议之规范请您知晓并同意</p>
                <p>2.本公司提供的所有服务，仅依其当前所呈现的状况提供，本公司保留不经事先通知为系统升级、维护或其它目的暂停提供服务任何部分的权利。</p>
                <p>3.本公司为您提供的帐号、密码在使用本平台过程中，任何行为及其结果由您承担全部责任，请您务必知晓并同意。</p>
                <p>4.您了解并同意，遵守中华人民共和国相关法律、法规的所有规定，如您的行为违反国家法律和法规的任何规定，有可能构成犯罪的，将被追究刑事责任，并由您承担全部法律责任。</p>
                <p>5.如果本公司有理由认为您的任何行为违反或可能违反国家法律和法规的任何规定，包括但不限于您的任何言论和其它行为，本公司可在任何时候不经任何事先通知终止向您提供服务。</p>
                <h4>四、注册义务</h4>
                <p>为了能安全、快捷地享受本公司提供的所有服务，请您确保在本平台上填写真实、有效、合法的资料。若您提供任何违法、违反道德或本公司认为不适合在平台上展示的内容，本公司有权暂停或终止您的帐号，并拒绝您现在和未来提供的全部或任何部分服务。</p>
                <p>本公司无须对任何用户的任何登记资料承担任何责任，包括但不限于鉴别、核实任何登记资料的真实性、正确性、完整性、适用性及/或是否为最新资料的责任。</p>
                <h4>五、用户信息及安全条款</h4>
                <p>您使用本平台过程中，应对所有使用您的密码及帐号的行为负完全的责任。请您知晓并同意：</p>
                <p>1.当您的帐号信息发生其它任何安全问题或异常时，请您立即通知本公司客服，因您未能及时上报问题而造成的损失，本公司不需承担任何责任；</p>
                <p>2.因您未保管好自己的帐号和密码等信息而产生的任何损失或损害，本公司无法也不承担任何责任；</p>
                <p>3.您要对自己帐号中的所有行为和事件负全责。因您未保管好自己的帐号和密码而对您自身、本公司或任何第三方造成的损害，您将负全部责任。</p>
                <h4>六、隐私权条款</h4>
                <p>1.请你知晓，您提供的登记资料及本公司保留的有关您的若干其它资料将受到中国有关的法律、法规和本公司《隐私声明》之规范。</p>
                <p>2.根据有关法律法规规定，请您知晓并同意，任何经由本平台而发布、上传的文字、资讯、资料、照片、图形、视讯、信息或其它资料（以下简称“内容 ”），无论系公开还是私下传送，均由内容提供者承担责任。牛文文仅为用户提供内容存储空间，无法控制经由本平台传送之内容，因此不保证内容的正确性、完整性或品质。</p>
                <p>3.您发布、上传的可能会令人不快、不适当或令人厌恶之内容，本公司有权依法停止传输任何前述内容，并采取相应行动，包括但不限于暂停您的全部或部分服务。</p>
                <p>4.您发布、上传的任何前述内容，本公司认为有违反中华人民共和国相关法律法规、违反道德及本平台相关规定的，本公司有权保存有关记录，并向有关机关报告。</p>
                <h4>七、用户行为</h4>
                <p>请您知晓并同意，您不会在任何时候、任何情况下利用本平台进行任何违法或不正当的活动，包括但不限于下列行为∶</p>
                <p>1.发布或以其它方式传送含有下列内容之一的信息：</p>
                <p>违反中华人民共和国宪法所确定的基本原则的；</p>
                <p>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
                <p>损害国家荣誉和利益的；</p>
                <p>煽动民族仇恨、民族歧视、破坏民族团结的；</p>
                <p>破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
                <p>散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
                <p>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
                <p>侮辱或者诽谤他人，侵害他人合法权利的；</p>
                <p>含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</p>
                <p>含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；</p>
                <p>含有本公司认为不适合在平台展示的任何形式的内容；</p>
                <p>2.以任何方式危害个人、单位的合法权益；</p>
                <p>3.将侵害他人著作权、专利权、商标权、商业秘密、或其它专属权利的内容，通过任何形式进行发布或以其它方式传送；</p>
                <p>4.将设计目的在于干扰、破坏或限制任何计算机软件、硬件或通讯设备功能之计算机病毒（包括但不限于木马程序（trojan horses）、蠕虫（worms）、定时炸弹、删除蝇（cancelbots）（以下简称“病毒”）或其它计算机代码、档案和程序之任何资料，加以发布、发送或以其它方式传送；</p>
                <p>5.干扰或破坏本服务或与本服务相连线之服务器和网络，或违反任何关于本服务连线网络之规定、程序、政策或规范；</p>
                <p>6.跟踪、人肉搜索或以其它方式骚扰他人；</p>
                <p>7.故意或非故意地违反任何适用的当地、国家法律，以及任何具有法律效力的规则；</p>
                <p>8.未经合法授权而截获、篡改、收集、储存或删除他人个人信息、站内邮件或其它数据资料，或将获知的此类资料用于任何非法或不正当目的。</p>
                <p>9.您知晓并同意，本公司为了保证用户利益，符合法律法规等相关规定，包括但不限于以下情况，本公司有权且在合理、必要的范围内，将内容加以保存或揭露：</p>
                <p>遵守法律法规相关规定、程序及准则；</p>
                <p>执行本服务协议相关规定；</p>
                <p>回应任何第三方机构或者第三人提出的权利主张；</p>
                <p>保护本公司、其本平台用户及公众的合法权益，保证财产或个人安全；</p>
                <h4>八、服务协议修改</h4>
                <p>本公司有权于任何时间暂时或修改或终止本服务协议的任何部分内容。请你知晓并同意对于本服务协议所作的任何修改、暂停或终止，本公司对您、任何第三人或机构均无需承担任何责任。</p>
                <h4>九、终止服务</h4>
                <p>您知晓并同意本公司基于包括但不限于平台运行、平台发展、平台升级等因素考虑，认为您已经违反本服务协议的使用规范及合作精神，本公司将停止或永久暂停您的账号信息，并终止为您提供的全部或部分服务，本公司也有权将您在本平台内的任何内容加以移除并删除。</p>
                <p>您知晓并同意，您在本平台上的帐号及您帐号中所有相关信息及文件到期，而您没有及时续费，本公司将停止或永久暂停您的账号信息，并终止为您提供的全部或部分服务，本公司也有权将您在本平台内的任何内容加以移除并删除，本公司对您或任何第三人均不承担任何责任。</p>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
    import ftCom from '../../components/footer';
    import nhCom from "../../components/navheader";
    import rtBar from "../../components/right";
    export default {
        name: "UserAgreement",
        components: {
            ftCom,
            nhCom,
            rtBar
        },
    }
</script>

<style scoped>
    .UserAgreement{
        background: #f4f4f4;
    }
    .xi .banner{
        height: 116px;
        background: url("../../assets/img/jbk_banner.png")center no-repeat;
    }
    .banner-text{
        width: 1200px;
        margin: 0 auto;
    }
    .banner-text h3{
        padding-top: 25px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }
    .banner-text p{
        font-size: 18px;
        color: #ffffff;
    }
    .agreement-box {
        margin: 30px auto;
        width: 1200px;
        padding: 20px;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid #e5e5e5;
    }
    .agreement-tit{
        padding: 10px 0 20px;
        border-bottom: 1px solid #e5e5e5;
    }
    .agreement-tit h3{
        font-size: 20px;
        color: #333333;
        text-align: center;
        font-weight: bold;
    }
    .agreement-content{
        padding: 25px 0;
    }
    .agreement-content p{
        text-indent: 28px;
        line-height: 2;
    }
    .agreement-content p.s-p{
        text-indent: 42px;
    }
    .agreement-content h4{
        text-indent: 28px;
        line-height: 1.7;
        padding: 15px 0;
    }
</style>

